import styled from '@emotion/styled';
import { MdArrowOutward } from "react-icons/md";

const Container = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const Link = styled.a`
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
    color: black;
`;

const StyledMdArrowOutward = styled(MdArrowOutward)`
    height: 28px;
    width: 28px;
    color: black;
`;

const CallToActionLink = ({children, ...linkProps}) => {
    return (
        <Container>
            <StyledMdArrowOutward />
            <Link {...linkProps}>{children}</Link>
        </Container>
    )
}

export default CallToActionLink;