import styled from '@emotion/styled';

const SidebarSection = styled.section`
    max-width: 384px;
	min-width: 384px;
	height: max-content;
    margin: 16px 0;
    padding: 16px;
	align-self: stretch;    
	border-radius: 8px !important;
    border: 1px solid #d3d3d3 !important;
    
	top: 0;
	background-color: rgb(255 255 255 / 50%) !important;

    &:first-of-type { margin-top: 0; }
    &:last-of-type { margin-bottom: 0; }
    
    &.call-to-action {
        background-color: #F4D557 !important;
        align-self: unset !important;
    }


    @media (max-width: 600px) {
        max-width: unset;
        min-width: unset;
        width: 100%;
    }
`;  

export default SidebarSection;