import { useContext, useEffect, useState } from 'react';
import { useMediaQuery }  from '@uidotdev/usehooks'

import HitmixBingoHeader from "./HitmixBingoHeader";
import Sidebar from './Sidebar';
import Hosts from './Hosts';
import GeolocationPrompt from './GeolocationPrompt'
import CountrySelector from './CountrySelector';
import SidebarExpando from './SidebarExpando';
import Loading from './Loading';
import LocationFilter from './LocationFilter';

import GlobalStateContext from '../contexts/GlobalStateContext';

import countryList from 'react-select-country-list'
import styled from '@emotion/styled';
import SidebarSection from './SidebarSection';
import CallToActionLink from './CallToActionLink';

const Wrapper = styled.div`
	display: flex;
	margin: 0 auto;
	width: 100%;

	@media (max-width: 850px) {
		flex-direction: column;	
	}
`;

const MainContent = styled.div`
	width: 100%;
	padding: 24px;
`;

const PageContainer = styled.div`
	height: 100vh;
	width: 100vw;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
`;


export default () => {
	
	const {
		searchQuery, setSearchQuery,
		filterOptions, setFilterOptions,
		userCountry, setUserCountry,
		filterCountry, setFilterCountry,
		browserSupportsGeolocation, browserGrantedGeolocation,
		isRequestingGeolocation
	} = useContext(GlobalStateContext);
	
	
	
	const [ isSidebarOpen, setIsSidebarOpen ] = useState(false);
	const [ mapBoxSearchValue, setMapBoxSearchValue ] = useState('');
	
	const isSmallDevice = useMediaQuery('only screen and (max-width: 600px)');
	
	useEffect(() => {
		if(userCountry){
			let [ filterCountry ] = countryList().getData().filter(country => country.value == userCountry);
			setFilterCountry(filterCountry);
		}
	}, [ userCountry ]);
	
	const handleSearchChange = evt => {
		const searchQuery = evt.currentTarget.value;
		setSearchQuery(searchQuery);
	}
	
	return (
		<PageContainer>
			<Wrapper>
				
				<Sidebar
					open={true}
				>
					<SidebarSection>
						<h3>Search:</h3>
						<h4>Names, companies, cities...</h4>
						<input
							type={"search"}
							placeholder={''}
							defaultValue={searchQuery}
							onChange={handleSearchChange}	
						/>
						
						<h3>Show hosts in country:</h3>					
						<CountrySelector
							filterCountries={filterOptions?.country || false}
							value={filterCountry}
							onChange={newValue => {
								let [ filterCountry ] = countryList().getData().filter(country => country.value == newValue);
								setFilterCountry(filterCountry);
							}}
						/>
						
						<h3>Filter by location:</h3>
						<LocationFilter />
					</SidebarSection>

					<SidebarSection className={'call-to-action'}>
						<CallToActionLink target="_blank" href={"https://purchase.hitmixbingo.com/update-host-directory"}>Create a Listing</CallToActionLink>
					</SidebarSection>
				</Sidebar>
				
				<MainContent>
					
					{isSmallDevice && browserSupportsGeolocation && browserGrantedGeolocation === null && (
						<GeolocationPrompt standalone={true} />
					)}
				
					{isRequestingGeolocation && <Loading label={"Getting your location..."} />}
				
					{!isRequestingGeolocation && <Hosts />}
				</MainContent>
				
			</Wrapper>
		</PageContainer>
	)
}