import styled from '@emotion/styled';
import classNames from 'classnames';
import { IoMdClose } from 'react-icons/io';

const Container = styled.div`
	color: white;
	margin: 24px 0 24px 24px !important;

	& h1, & h2, & h3, & h4, & h5 { color: black; }

	@media (max-width: 850px) {
		min-width: unset;
		max-width: unset;
		margin: 24px 24px 0 24px !important;
		width: calc(100% - 48px);
	}
`;

const SidebarCloseButton = styled.div`
	background-color: rgba(255,255,255,.35);
	min-width: 48px; min-height: 48px;
	max-width: 48px; max-height: 48px;
	border-radius: 128px;
	border: 2px solid rgba(255,255,255,.5);
	
	display: flex;
	align-items: center; justify-content: center;
	
	font-size: 24px;
	color: white;
	cursor: pointer;
	
	@media (min-width: 850px) {
		display: none;
	}
`;

export default ({
	children,
	open, setIsOpen,
	className}) => {
	return (
		<Container className={
			classNames([
				className,
				{ open }
			])
		}>
			{children}
		</Container>
	)
}